import Navbar from "./components/Components-Navbar";
import Skill from "./page/Skill";
import Home from "./page/Home";
import FooterComponent from "./components/Components-Footer";
import TestHome from "./test/Home";

function App() {
  return (
    <>
      {/* <Navbar /> */}
      <TestHome />
      {/* <Home /> */}
      <Skill />
      <FooterComponent />
    </>
  );
}

export default App;
