import React, { useState } from "react";

const NavBarComponents = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const data = [
    { id: 1, link: "Home" },
    { id: 2, link: "Skill" },
  ];

  const handleClick = (link) => {
    const section = document.getElementById(link);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full pt-2 h-fit px-10 text-white flex justify-between items-center z-50">
      <div>
        <h1 className="text-2xl font-bold font-signature ml-2">Portofolio</h1>
      </div>
      <ul className="hidden md:flex space-x-4">
        {data.map(({ id, link }) => (
          <li
            key={id}
            onClick={() => handleClick(link)}
            className=" p-2 hover:rounded-md text-xl cursor-pointer capitalize font-medium text-white hover:scale-105 transition-transform duration-200"
          >
            {link}
          </li>
        ))}
      </ul>
      <div
        onClick={() => setShowNavbar(!showNavbar)}
        className="cursor-pointer bg-white rounded-lg p-1 z-10 text-white md:hidden"
      >
        {showNavbar ? (
          <img
            src="https://cdn-icons-png.flaticon.com/512/1828/1828778.png"
            alt="Menu"
            className="w-6 h-6  rounded"
          />
        ) : (
          <img
            src="https://cdn-icons-png.flaticon.com/512/14267/14267486.png"
            alt="Menu"
            className="w-6 h-6  rounded"
          />
        )}
      </div>

      {showNavbar && (
        <ul className="flex flex-col mt-16 items-end absolute top-0 right-10 w-full backdrop-blur md:hidden">
          {data.map(({ id, link }) => (
            <li
              key={id}
              onClick={() => {
                handleClick(link);
                setShowNavbar(false);
              }}
              className=" p-2 hover:rounded-md px-4 py-2 text-md cursor-pointer capitalize font-medium text-white hover:scale-105 transition-transform duration-200"
            >
              {link}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavBarComponents;
