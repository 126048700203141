import React, { useState } from "react";
import Fotoku from "../assets/foto.jpg";
import FotoSekolah from "../assets/foto-sekolah.jpg";
import Dropdown from "../Button/buttonDropdown";
import Navbar from "../components/Components-Navbar";
import "../border.css";

function Home() {
  const textFSD = `Hi, Saya merupakan fresh graduate lulusan S1-Informatika di
            Universitas Amikom Yogyakarta dengan IPK 3.47, memiliki kemampuan
            dalam hal HTML/CSS, PHP, JavaScript..`;

  const [title, setTitle] = useState("Full Stack Developer");
  const [text, setText] = useState(textFSD);
  const [foto, setFoto] = useState(Fotoku);

  const handleFSD = () => {
    setTitle("Full Stack Developer");
    setText(textFSD);
    setFoto(Fotoku);
  };

  const handleButtonSekolah = () => {
    setTitle("History School");
    setText(<Dropdown />);
    setFoto(FotoSekolah);
  };

  return (
    <div name="Home" id="Home" className="h-full bg-black text-white pt-16 ">
      <Navbar />
      <div className="min-h-screen mx-auto flex flex-col items-center justify-center px-4 md:flex-row  ">
        <div className=" flex flex-col justify-center items-center h-fit mr-5">
          <h2 className="text-3xl sm:text-6xl font-bold bg-gradient-to-r from-white via-stone-500 to-stone-700 bg-clip-text text-transparent ">
            {title}
          </h2>
          <p className=" text-white text-center md:text-left py-4 max-w-xl">
            {text}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={foto}
            alt="fotoku"
            className=" animate-border rounded mx-auto w-64 md:w-full h-80 object-cover"
          />
          {/* Buttons placed below the image */}
          <div className="flex my-5 gap-5">
            <button onClick={handleFSD} className=" border rounded-lg">
              <figure className="relative max-w-sm transition-all duration-300 cursor-pointer">
                <img
                  src={Fotoku}
                  alt="Sekolah"
                  className="rounded-lg w-16 h-20 sm:w-24 sm:h-28 md:w-24 md:h-24"
                />
                <figcaption className="absolute px-2 sm:px-4 text-sm sm:text-md text-white bottom-1 shadow">
                  <p>Full Stack Dev</p>
                </figcaption>
              </figure>
            </button>

            <button onClick={handleButtonSekolah}>
              <figure className="relative max-w-sm transition-all duration-300 cursor-pointer filter grayscale hover:grayscale-0">
                <img
                  src={FotoSekolah}
                  alt="Sekolah"
                  className="rounded-lg w-16 h-20 sm:w-24 sm:h-28 md:w-24 md:h-24"
                />
                <figcaption className="absolute px-2 sm:px-4 text-sm sm:text-lg text-white bottom-1 shadow">
                  <p>Sekolah</p>
                </figcaption>
              </figure>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
