import React, { useState } from "react";
import Fotoku from "../assets/foto.jpg";
import Dropdown from "../Button/buttonDropdown";
import FotoSekolah from "../assets/foto-sekolah.jpg";
import Navbar from "../components/Components-Navbar";
import "../border.css";

const Home = () => {
  const textFSD = `Hi, Saya merupakan fresh graduate lulusan S1-Informatika di
            Universitas Amikom Yogyakarta dengan IPK 3.47, memiliki kemampuan
            dalam hal HTML/CSS, PHP, JavaScript..`;

  const [title, setTitle] = useState("Full Stack Developer");
  const [text, setText] = useState(textFSD);
  const [foto, setFoto] = useState(Fotoku);

  const handleFSD = () => {
    setTitle("Full Stack Developer");
    setText(textFSD);
    setFoto(Fotoku);
  };

  const handleButtonSekolah = () => {
    setTitle("History School");
    setText(<Dropdown />);
    setFoto(FotoSekolah);
  };

  return (
    <div id="Home" name="Home" className="min-h-screen bg-black">
      <Navbar />
      <div className="min-h-screen flex flex-col justify-center items-center">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-[2fr,1fr] xl:grid-cols-[2fr,1fr] gap-5 w-full max-w-5xl px-3 md:px-5">
          {/* Text Section */}
          <div className="flex flex-col justify-center text-center md:text-left w-full md:w-[60vw] lg:w-[45vw] mt-10 md:mt-16 sm:pr-10">
            <h2 className="text-3xl sm:text-2xl md:text-4xl lg:text-5xl font-bold bg-gradient-to-r from-white via-stone-500 to-stone-700 bg-clip-text text-transparent">
              {title}
            </h2>
            <p className="text-white text-lg sm:text-md md:text-lg lg:text-xl mx-4 md:mx-0">
              {text}
            </p>
          </div>

          {/* Image Section */}
          <div className="flex flex-col items-center mt-6 md:mt-0">
            <img
              src={foto}
              alt="gambar"
              className="animate-border rounded mx-auto max-w-60 md:w-full h-60"
            />

            <div className="flex flex-row justify-center mt-4 gap-4 md:gap-6">
              {/* Full Stack Dev Button */}
              <button onClick={handleFSD} className="rounded-lg border">
                <figure className="relative max-w-sm transition-all duration-300 cursor-pointer">
                  <img
                    src={Fotoku}
                    alt="Full Stack Dev"
                    className="rounded-lg w-16 h-20 sm:w-20 sm:h-24"
                  />
                  <figcaption className="absolute bottom-1 left-1 px-2 sm:px-4 text-sm sm:text-md text-white shadow">
                    <p>Full Stack Dev</p>
                  </figcaption>
                </figure>
              </button>

              {/* History School Button */}
              <button
                onClick={handleButtonSekolah}
                className="rounded-lg border"
              >
                <figure className="relative max-w-sm transition-all duration-300 cursor-pointer">
                  <img
                    src={FotoSekolah}
                    alt="Sekolah"
                    className="rounded-lg w-16 h-20 sm:w-20 sm:h-24 grayscale"
                  />
                  <figcaption className="absolute bottom-1 left-1 px-2 sm:px-4 text-sm sm:text-md text-white shadow">
                    <p>History School</p>
                  </figcaption>
                </figure>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
