import { useEffect, useState } from "react";
import Porto1 from "../assets/porto1.JPG";
import Porto2 from "../assets/porto2.JPG";
import Porto3 from "../assets/porto3.JPG";
import "../border.css";
import skill from "../json/skill.json";

var contentupnormal = `<a href="https://upnormal.4lp4.my.id/">Link Website Warunk Upnormal</a>`;
var contenttodos = `<a href="https://www.todos.4lp4.my.id/public/">Link Website Todos</a>`;
var contentwarket = `<a href="https://www.warket.4lp4.my.id/public/">Link Website Warket</a>`;
const History = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(skill);
  }, []);

  return (
    <div
      id="Skill"
      name="Skill"
      className="min-h-screen  bg-black text-white flex flex-col items-center justify-center py-20"
    >
      <h2 className="text-3xl sm:text-6xl font-bold mb-12 mt-8 bg-gradient-to-r from-white via-stone-500 to-stone-700 bg-clip-text text-transparent">
        Portofolio
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center justify-center p-4">
        {data.map((list, i) => (
          <div
            key={i}
            className="border-2 mx-auto w-full md:w-3/4 lg:w-4/8 flex flex-col items-center text-left px-4 py-4 animate-border bg-white bg-opacity-15 backdrop-filter backdrop-blur-lg"
          >
            <h5 className="text-2xl mb-4">{list.title}</h5>
            <img
              src={
                list.image === "warungupno"
                  ? Porto1
                  : list.image === "todos"
                  ? Porto3
                  : list.image === "warket"
                  ? Porto2
                  : ""
              }
              alt={list.image}
            />
            {list.description} <br />
            <br />
            <span>Teknologi: {list.skill}</span>
            <div
              className="hover:text-blue-500 pt-9"
              dangerouslySetInnerHTML={
                list.link === "contentupnormal"
                  ? {
                      __html: contentupnormal.replace(
                        /href/g,
                        "target='_blank' href"
                      ),
                    }
                  : list.link === "contenttodos"
                  ? {
                      __html: contenttodos.replace(
                        /href/g,
                        "target='_blank' href"
                      ),
                    }
                  : list.link === "contentwarket"
                  ? {
                      __html: contentwarket.replace(
                        /href/g,
                        "target='_blank' href"
                      ),
                    }
                  : undefined
              }
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
