import React from "react";
import Study from "../json/study.json";

function Dropdown() {
  return (
    <>
      {Study.map((data, index) => (
        <div className="p-2">
          <p className="font-bold text-base sm:text-lg lg:text-xl">
            {data.school} <span>({data.year})</span>
          </p>
          <p className="text-white text-xs sm:text-sm lg:text-base">
            {data.study}
          </p>
        </div>
      ))}
    </>
  );
}

export default Dropdown;
